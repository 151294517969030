//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import Reporter from '@/components/Reporter/Reporter'
export default {
    name: "Comment",
    created(){
      if (this.comment.user_id != this.$store.state.user.id) {
        this.$http({
          url: 'profiles/' + this.comment.user_id + '/photos?type=profile',
          method: 'GET' 
        }).then((response) => {
          if (response.data['status'] == 'profile_photo_retrieved') {
            if (response.data['body']['profile_photo'] != 'not_available') {
              if(response.data['body']['profile_photo'] == '@/assets/default_profile.webp'){
                this.commenterPhoto = require('@/assets/default_profile.webp');
              }else{
                this.commenterPhoto = response.data['body']['profile_photo'];
              }
            }
          }
        });
      }
    },
    props: [
        'comment',
        'post_owner_id'
    ],
    components: {
      Reporter
    },
    data() {
        return {
            commentMenu: false,
            editMode: false,
            isDeleted: false,
            commenterPhoto: require('@/assets/default_profile.webp'),
            showReporter: false
        }
    },
    methods: {
        save_comment() {
            this.$http({
                url: 'comments/' + this.comment.id,
                method: 'PUT',
                data: {
                    body: this.comment.body
                }
            }).then((response) => {
                if (response.data['status'] == 'comment_updated') {
                    this.$q.notify({
                        message: 'You have successfully edited your comment.',
                        color: 'positive',
                        icon: 'check_circle'
                    });
                } else {
                    this.$q.notify({
                        message: 'We cannot update your comment. Please try again.',
                        color: 'negative',
                        icon: 'error'
                    });
                }
            });
        },
        delete_comment() {
          this.$q.dialog({
              title: "Remove your comment?",
              message: 'Are you sure that you want to delete this comment?',
              cancel: true,
              persistent: true,
              ok: {
                label: 'Yes'
              }
            }).onOk(() => {
              this.$http({
                url: 'comments/' + this.comment.id,
                method: 'DELETE'
              }).then((response) => {
                if (response.data['status'] == "comment_deleted") {
                  this.isDeleted = true; 
                  this.$q.notify({
                    message: 'The comment has been successfully deleted.'
                  })
                } else {
                    this.$q.notify({
                        message: 'There is a problem deleting your comment. Please try again.',
                        color: 'negative',
                        icon: 'error'
                    })
                }
              })
            })    
        },
        getName() {
            return this.comment.first_name + ' ' + this.comment.last_name;
        }
    },
    computed: {
        isPostOwner() {
            if (this.post_owner_id == this.$store.state.user.id) {
                return true;
            }
            return false;
        }, 
        isCommentOwner() {
            if (this.comment.user_id == this.$store.state.user.id) {
                return true;
            }
            return false;
        }
    },

    filters: {
      filterPostStamp(date){
        return moment.unix(date).format('LL') + ' at ' + moment.unix(date).format('LT')
      },

      filterLinks(post){
        var post_array = []
        const domain_names = [
          '.com','.net','.ph','.org','.dev','.gov','.edu','.asp'
        ]
        post = post.split(" ")
        post.map((x) => {
          if(x.includes('http') || ( x.includes('http') && domain_names.some(domain => x.includes(domain)))){
            x = "<a href='"+x+"' class='comment-link' target='_blank'>"+x+"</a>"
          }
          post_array.push(x)
        })
        post_array =  post_array.join(" ")
        return post_array
      },
    }
}
